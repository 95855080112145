<template>
  <div id="pre-totem" class="background-totem" @click="()=> (!selectPayments && itsAvailable)?selectPayments=true: null">
    <v-container class="text-center">
      <v-img class="ma-auto mt-4"  max-width="250" src="/images/logo_marina%201.png"></v-img>
      <div class="d-flex flex-column">
        <div class="margin-totem">
          <div class="epitext white--text">Kiosko Autoservicio</div>
        </div>
        <div class="margin-totem" v-if="itsAvailable">
          <div class="totem-title white--text">Pulsa para empezar<br> tu pedido</div>
        </div>
        <div class="margin-totem" v-else>
          <div class="totem-title white--text">No hay evento<br> disponible</div>
        </div>
        <v-img class="ma-auto mt-15" v-if="itsAvailable"  max-width="150" src="/images/touchDisplay.png"></v-img>
      </div>
    </v-container>
    <v-overlay v-show="selectPayments">
      <div class="d-flex justify-center flex-column">
        <v-card class="white ma-5 pa-6" max-width="374"  elevation="2" @click="haveReservation">
          <div class="text-center">
            <v-icon class="text-h2" color="blue">mdi-credit-card-outline</v-icon>
          </div>
          <v-card-title class="blue--text ma-auto" style="width: 13rem;">Tengo saldo en mi reserva</v-card-title>
        </v-card>
        <v-card class="white ma-5 pa-6" max-width="374" elevation="2" @click="notHaveReservation">
          <div class="text-center">
            <v-icon color="blue" class="text-h2" >mdi-credit-card-off-outline</v-icon>
            <v-card-title class="blue--text ma-auto" style="width: 13rem;">No tengo saldo en mi reserva</v-card-title>
          </div>
        </v-card>
      </div>
    </v-overlay>
    <v-overlay v-show="codEntry">
      <div class="d-flex justify-center flex-column">
        <v-card class="white ma-5 pa-6" elevation="2">
          <v-card-subtitle class="black--text">Introduzca el código de su entrada o aproxímela al lector</v-card-subtitle>
          <v-text-field
              prepend-inner-icon="mdi-ticket"
              outlined
              @focus="keyboardTicketShow = true"
              autofocus
              @input="onTicketInputChange"
              :value="ticketCode"
          ></v-text-field>
          <v-card-actions>
            <v-btn block color="orange" flat @click="getReservation" :loading="searching"> Ingresar</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn block color="red" flat @click="closePopUp">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-overlay>
    <v-overlay v-show="newOrder">
      <div class="d-flex justify-center flex-column">
        <v-card class="white ma-5 pa-6" elevation="2">
          <v-card-subtitle class="black--text">Ingrese su Movil</v-card-subtitle>
          <v-text-field
              prepend-inner-icon="mdi-phone"
              outlined
              @input="onPhoneInputChange"
              :value="cellphone"
              @focus="keyboardPhoneShow = true"
          ></v-text-field>
          <v-card-actions>
            <v-btn block color="orange" flat @click="storePhone" :loading="searching">Ingresar</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn block color="red" flat @click="closePopUp">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-overlay>
    <v-bottom-sheet hide-overlay inset v-model="keyboardTicketShow" persistent>
      <numpad-keyboard
          @onChange="onTicketChange" :input="ticketCode"
      >
      </numpad-keyboard>
    </v-bottom-sheet>
    <v-bottom-sheet hide-overlay inset v-model="keyboardPhoneShow" persistent>
      <numpad-keyboard
          @onChange="onPhoneChange" :input="cellphone"
      >
      </numpad-keyboard>
    </v-bottom-sheet>

  </div>
</template>

<script>
import {AmphiEvents, Reservation} from "@/services/api";
import NumpadKeyboard from "@/components/numpadKeyborad";
export default {
  name: "PreTotem",
  components: {NumpadKeyboard},
  data(){
    return {
      selectPayments: false,
      codEntry: false,
      ticketCode:null,
      searching:false,
      newOrder: false,
      cellphone: null,
      itsAvailable: true,
      keyboardTicketShow : false,
      keyboardPhoneShow : false,
    }
  },
  mounted() {
    if(this.termialId){
      this.$store.commit('setTerminal', this.termialId)
    }
    // else{
    //   alert("NO TERMINAL SET")
    // }
    AmphiEvents().getEventOpen().then( (res) =>
        {
          this.$store.commit('setLoading', false)
          this.$store.commit('setTotemEvent', res.data)
        }
    )
        .catch( () => {
              // this.itsAvailable = false
              this.$store.commit('setTotemEvent', {"data":{"id":378,"amphi_event_id":20,"schedule":"2022-03-06T22:35:00+00:00"}})
              this.$store.commit('setLoading', false)
            }
        )

  },
  computed:{
    reservationRequest(){
      return Reservation()
    },
    termialId(){
      return this.$route.query.terminalid || null;
    }
  },
  methods:{
    onTicketChange(input) {
      this.ticketCode = input;
    },
    onTicketInputChange(value) {
      this.ticketCode = value;
      if(value.length === 6){
        this.getReservation();
      }
    },
    onPhoneChange(input) {
      this.cellphone = input;
    },
    onPhoneInputChange(value) {
      this.cellphone = value;
    },
    storePhone(){
      if(this.cellphone){
        this.searching = true
        this.keyboardPhoneShow = false
        this.$store.commit('setPhone', this.cellphone)
        this.$router.push("/totem")
      }else{
        console.log("nothing")
      }
      this.searching = false
    },
    haveReservation(){
      this.selectPayments = false
      this.codEntry = true
    },
    notHaveReservation(){
      this.selectPayments = false
      this.newOrder = true
    },
    closePopUp(){
      this.newOrder = this.newOrder = this.codEntry = this.keyboardTicketShow = this.keyboardPhoneShow = false
    },
    getReservation(){
      this.keyboardTicketShow = false
      this.searching  = true
      this.reservationRequest.getTicket(this.ticketCode).then(
          response => {

            this.reservationRequest.get(response.data.data.amphi_reservation_id).then(
                res => {
                  this.$store.commit('setReservation', res.data.data)
                  this.$router.push("/totem")
                }

            )


          }
      ).catch(e =>{
        console.log(e)
        this.searching = false
        alert("No es posible encontrar la reserva")
      })
    }
  }
}
</script>

<style lang="scss">

#pre-totem{
  &.background-totem{
    background: #1B69A4;
    height: 100vh;
  }
  .margin-totem{
    margin-top: 120px;
  }
  .epitext{
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: 105px;
    letter-spacing: 3.791666030883789px;
    text-align: center;
  }
  .totem-title{
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 4.458333492279053px;
    text-align: center;

  }
  .v-input{
    .v-input__slot{
      background: rgba(149, 149, 149, 0.47);
    }
  }
}
</style>